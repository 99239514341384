import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './GroupScreen.css';
import Nav from '../../components/Nav/Nav'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../features/currentUser'
import { Oval, MagnifyingGlass } from 'react-loader-spinner'
import { getGroupById, getPostWithId, getUserInfoWithId } from '../../services/f';
import { BsPlusCircle, BsInfoCircle, BsFillCheckCircleFill, BsFillHeartFill } from 'react-icons/bs'  //https://react-icons.github.io/react-icons
import { FaComment, FaShare } from 'react-icons/fa'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { PiUserCircleLight } from 'react-icons/pi'
import Footer from '../../components/Footer';
import { auth } from '../../firebase';
import VerifyEmailComponent from '../../components/rememberverifyemail';
import { Helmet } from 'react-helmet';
import { TwitterShareButton } from 'react-twitter-embed';
//see which one google indees and which notif there are dofferences
//check faq groupitem app profile
function GroupScreen() {
  // http://localhost:3000/groupitem?id=sdffsdf
  // http://localhost:3000/group?id=MFbwMsG0SkVnOYTUMZRKeshqBmA2
  const [searchParams, setSearchParams] = useSearchParams();
  let currentUser = useSelector(getCurrentUser).currentUser?.currentUser// ALSO WENNS n user gibt dann wird anderes screen angezeigt
  const navigate = useNavigate()

  const [groupitem, setgroupitem] = useState(null);
  const [copiedlink, setcopiedlink] = useState(false);

  let titledescription
  let titletext

  useEffect(() => {
    let groupid = searchParams.get("id")
    if (groupid === null || groupid === undefined || groupid === "") {
      setgroupitem("groupnotexists")
    } else {
      getGroupById(groupid).then((res) => {
        //console.log("getPostWithId result", res)
        setgroupitem(res)
        titledescription = "Group description:  " + res[1]?.description + "Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute."
        titletext = "Premsi - Group: " + res[1]?.grouptitle
        if (res === "groupnotexists") {
          return
        }
      })
    }

  }, []);

  return (
    <div className='postscreen'>
      <Helmet>
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Premsi | Group - Easily sell your digital content❤️" />
        <meta property="og:description" content="Premsi | Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
        <meta property="og:url" content="https://premsi.com/group" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
        <meta property="og:site_name" content="Premsi - sell digital content" />
        <link rel="canonical" href="https://premsi.com/group" />

        <title>{titletext}</title>
        <meta name="description" content={String(titledescription)} />

        <meta name="twitter:title" content="Premsi" />
        <meta name="twitter:description" content={`New Premsi.com Group`} />
        <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Nav />
      {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}

      <div className='postscreen__body'>

        {groupitem === null ?
          <div>
            <Oval
              height={40}
              width={40}
              color="#009FFD"
              wrapperStyle={{ marginTop: 20, flex: "display", alignItems: "center", justifyContent: "center" }}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="white"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
            <p style={{ marginBottom: 3, textAlign: "center" }}>Loading group...</p>
            <p style={{ textAlign: "center", fontSize: 12 }}>If this takes too long please refresh this window.</p>
          </div>
          :
          (
            groupitem === "groupnotexists"
              ?
              <div>
                <MagnifyingGlass
                  height={40}
                  width={40}
                  color="#009FFD"
                  wrapperStyle={{ marginTop: 20, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="white"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
                <h1 style={{ marginBottom: 3, textAlign: "center", color: "black", fontSize: 16 }}>No Group...</h1>
                <h2 style={{ textAlign: "center", fontSize: 12, marginTop: 20 }}>It seems that you have a wrong link and this group doesn't exist</h2>
                <p style={{ textAlign: "center", fontSize: 12 }}>Please recheck your link.</p>
                <div style={{ display: "flex", alignItems: "center", marginTop: 20, justifyContent: "center", width: "100%", marginBottom: 5 }}>
                  <button onClick={() => navigate("/")} style={{ fontSize: 14, fontWeight: "bold", paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 10, backgroundColor: "#009FFD", color: "white", borderWidth: 0 }}>Explore others</button>
                </div>
              </div>
              :
              <>
                <div className='postscreen__info'>
                  {/*<div className='profilepicdiv'>
                    <img style={{ pointerEvents: "none" }} className='avatarpostscreen' src={groupitem[1]?.media} alt="group pic" />
          </div>*/}

                  <div className='profilepicdiv'>
                    <h1 style={{ color: "black", fontSize: 16 }}>Premsi Group:</h1>
                  </div>

                  <div className='postscreen__details'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <button onClick={() => navigate("/user?id=" + groupitem[1]?.creator)} className='useremail'>{groupitem[1]?.grouptitle}</button>
                    </div>

                    <div className='userprofilecounts'>
                      <div style={{ textAlign: "center" }}>
                        <p style={{ fontSize: 12, fontWeight: "bold" }}>Members</p>
                        <p style={{ fontSize: 12 }}>{(groupitem[1]?.membersCount)}</p>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <p style={{ fontSize: 12, fontWeight: "bold" }}>Messages</p>
                        <p style={{ fontSize: 12 }}>{(groupitem[1]?.messagesCount)}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='groupdescription'>
                  <div className='groupdescriptioninner'>
                    <h5 style={{ textAlign: "center" }}>{groupitem[1]?.description}</h5>
                  </div>
                </div>

                <div className='postdiv'>
                  <img
                    loading="lazy"
                    key={groupitem[0]}
                    src={groupitem[1]?.media}
                    style={{ pointerEvents: "none", height: "100%", cursor: "pointer", aspectRatio: 12 / 16, borderRadius: 10, objectFit: "contain" }}
                    onError={(i) => (i.target.src = require('../../images/pblue-min.webp'))}
                    onClick={() => null}
                    alt={groupitem[1]?.description}
                  />

                </div>

                <h2 className='belowuserinfo'>To join this group please <a href="app" style={{ color: "white" }}>download</a> our App.</h2>


                <div className='postinfo'>
                  <div className='postinnerinfo'>

                    <button className='sharebtn' onClick={() => (navigator.clipboard.writeText("www.premsi.com/group?id=" + groupitem[0]), setcopiedlink(true))}>
                      {
                        copiedlink
                          ?
                          <p>Copied ✅</p>
                          :
                          <>
                            <FaShare size={22} color='white' alt='share post' />
                            <p>Share Group</p>
                          </>
                      }
                    </button>
                    <TwitterShareButton
                      onLoad={function noRefCheck() { }}
                      options={{
                        buttonHashtag: undefined,
                        screenName: undefined,
                        size: 'large',
                        text: "See this new Premsi Group 🔥🧿",
                        //via: 'saurabhnemade'
                      }}
                      placeholder={<div style={{ backgroundColor: 'transparent', color: 'white', margin: 10, padding: 10, textAlign:"center",fontSize:11 }}>loading x</div>}
                      url={`https://www.premsi.com/group?id=${groupitem[0]}`}
                    />
                  </div>
                </div>
              </>
          )
        }
      </div>

      <Footer />

    </div>
  )
}

export default GroupScreen



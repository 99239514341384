import React, { useRef, useState } from 'react'
import './PremsiCalculatorScreen.css'
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import VerifyEmailComponent from '../../components/rememberverifyemail';
import { auth } from '../../firebase';
import { Helmet } from 'react-helmet';

function PremsiCalculator() {
    const [showearnings, setshowearnings] = useState(1);

    const [followercount, setfollowercount] = useState(0);
    const [nrpms, setnrpms] = useState(0);
    const [avgpricepms, setavgpricepms] = useState(0);
    const [nrgroups, setnrgroups] = useState(0);
    const [avgpricegroups, setavgpricegroups] = useState(0);

    const [earnings, setearnings] = useState();

    const myRef = useRef(null)
    const navigate = useNavigate()

    const calculateEarnings = () => {
        let nrbuyerpm = 0.005 * followercount
        let nrbuyergroup = 0.005 * followercount

        let earnedpm = nrpms * avgpricepms * nrbuyerpm * 0.3
        let earnedgroup = nrgroups * avgpricegroups * nrbuyergroup * 0.3 * 3

        //console.log("calculateEarnings", nrbuyerpm, nrbuyergroup, earnedpm, earnedgroup)

        setearnings(Number(earnedgroup) + Number(earnedpm))
    }

    return (
        <>
            <Helmet>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Premsi | Calculate your potential earnings with Premsi" />
                <meta property="og:description" content="Premsi | Calculate your potential earnings with Premsi . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/calculator" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
                <meta property="og:site_name" content="Premsi - sell digital content" />

                <title>Premsi | Calculate your potential earnings with Premsi</title>
                <meta name="description" content="Premsi | Calculate your potential earnings with Premsi . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <link rel="canonical" href="https://premsi.com/calculator" />
            </Helmet>
            <Nav />
            {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}
            <div style={{ marginTop: 25, marginBottom: 15, display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                <img loading="lazy" src={require('../../images/1pres.webp')} style={{ objectFit: "contain", width: "70%", borderRadius: 30 }} alt="Premsi.com presentation explanation" />
            </div>

            <div className='whatispremsimainmain'>
                <div className='whatispremsitop' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                    <p style={{ fontSize: 23, fontWeight: "bold", color: "black" }}>What is Premsi? </p>
                    <img loading="lazy" src={require('../../images/pblue-min.webp')} style={{ marginLeft: 10, height: 25, width: 25, borderRadius: 15 }} alt="Premsi.com logo app icon" />
                </div>

                <p className='premsiexplanationp'>Premsi is the social media where you can post, market and sell all your digital content in our App + Website in 30sec 🔥</p>

                <div className='whatispremsimainhome'>
                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>⏱</p>
                        <p>1 minute to start selling</p>
                    </div>

                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>🏦</p>
                        <p>Premsi is free</p>
                        <p>No monthly subscription</p>
                        <p>85% daily payout</p>
                    </div>

                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>⭐️</p>
                        <p>Marketing / Selling in an one-in-all App + Website</p>
                    </div>

                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>🔥</p>
                        <p>Fans can buy your content without logging in</p>
                    </div>
                </div>

                {/* here have social media icons */}

                <div className='socialbar' >
                    <p style={{ fontSize: 15, opacity: 0.75 }}>seen on</p>
                    <div className='innersocialbigger' >
                        <img className='socialbarimage' alt='Hacker news logo' src={require('../../images/socialforbar/hn.webp')} />
                        <img className='socialbarimage' alt='Twitter X logo' src={require('../../images/socialforbar/x.webp')} />
                    </div>
                    <div className='innersocialbigger' >
                        <img className='socialbarimage' alt='Tiktok logo' src={require('../../images/socialforbar/tt.webp')} />
                        <img className='socialbarimage' alt='Product Hunt logo' src={require('../../images/socialforbar/producthunt.webp')} />
                    </div>
                    <div className='innersocialsmaller' >
                        <img className='socialbarimage' alt='Instagram logo' src={require('../../images/socialforbar/ig.webp')} />
                    </div>
                </div>

                {/*Here start explained our features */}
                <div style={{ display: "flex", marginTop: 0, alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                    <p style={{ fontSize: 20, fontWeight: "bold", color: "black",textAlign:"center", width: "70%" }}>Calculate your potential earnings with Premsi </p>
                    <img loading="lazy" src={require('../../images/pblue-min.webp')} style={{ height: 25, width: 25, borderRadius: 15 }} alt="Premsi.com logo app icon" />
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 5, marginBottom: 10 }}>
                    <p className='calcp'>Your Social Media Following:</p>
                    <input onChange={(e) => setfollowercount(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='number' placeholder='# followers' />
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                    <p className='calcp'>Premium Messages:</p>
                    <p style={{ width: 340, textAlign: "center", marginBottom: 7, fontSize: 13 }}>How many different Premium Messages (Videos/Pics/PDFs) do you think you will sell?</p>
                    <input onChange={(e) => setnrpms(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='number' placeholder='# of PMs' />
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                    <p style={{ width: 340, textAlign: "center", marginBottom: 7, fontSize: 13 }}>Average price of PMs:</p>
                    <input onChange={(e) => setavgpricepms(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='number' placeholder='Avg. price of PMs' />
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                    <p className='calcp'>Paid Groups:</p>
                    <p style={{ width: 250, textAlign: "center", marginBottom: 7, fontSize: 13 }}>How many different Paid Groups do you think you will offer?</p>
                    <input onChange={(e) => setnrgroups(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='number' placeholder='# of Groups' />
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                    <p style={{ width: 340, textAlign: "center", marginBottom: 7, fontSize: 13 }}>Average monthly price of all Groups:</p>
                    <input onChange={(e) => setavgpricegroups(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='number' placeholder='Avg. price of Groups' />
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                    <p style={{ width: 250, textAlign: "center", marginBottom: 7, fontSize: 16, fontWeight: "bold" }}>Soon you'll be able to sell courses and paid podcasts ❤️</p>
                </div>

                <div onClick={() => calculateEarnings()} style={{ height: 40, width: 280, cursor: "pointer", backgroundColor: "#009ffd", borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ fontSize: 14, fontWeight: "bold", color: "white" }}>Calculate potential earnings 💰</p>
                </div>

                {earnings === null || earnings === undefined
                    ?
                    null
                    :
                    <>
                        <p style={{ marginTop: 40, fontSize: 17, fontWeight: "bold", textAlign: "center", width: "80%", color: "green" }}>Your potential earnings are {earnings}$ 🔥</p>

                        <div onClick={() => navigate("/")} style={{ height: 40, marginTop: 30, width: 200, cursor: "pointer", backgroundColor: "#009ffd", borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p style={{ fontSize: 14, fontWeight: "bold", color: "white" }}>Start Now ❤️</p>
                        </div>
                    </>
                }

                {/* bei text dann schreiben + TIPS */}

                {/*Here end explained our features */}
                <div className='everyuserdivsecondnotlogin'>
                    <div className='everyuserdivinnersecondnotlogin'>
                        <p style={{ fontSize: 24, fontWeight: "bold", color: "white" }}>Fees 💰</p>
                        <p className='priceexplanation'>And all that for 0$. No monthly subscription. No credit card required. Nothing!</p>
                        <p className='priceexplanation'>We only earn once you make money and 85% goes directly to you</p>
                    </div>
                </div>
         
            </div>

            <div className='referralmaindiv'>
                <div className='referralinnerdiv'>
                    <div className='referralfirstinnerdiv'>
                        <h2 style={{ color: "white", width: "85%" }}>Refer friends & receive 5% of their spendings for life 🔥</h2>
                        <h4 style={{ width: "90%" }}>Whenever they subscribe to Premsi+, tip someone or buy a premium message you'll earn.</h4>
                        <button className='referfriend__button' onClick={() => (navigate("/profile"))}>Refer a friend</button>
                    </div>

                    <div className='referralfirstinnerdiv'>
                        <img loading="lazy" style={{ height: "100%" }} src={require('../../images/referral.webp')} alt="Premsi.com referral bonus" />
                    </div>
                </div>
            </div>

            {/*<div className='explainappdivwhatcreatorsachieved'>
                <h2>What our creators achieve ❤️</h2>

                {showearnings === 1 ?
                    <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/1pm.webp')} alt="Premsi.com earnings example" />
                    : (showearnings === 2 ?
                        <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/2pm.webp')} alt="Premsi.com earnings example" />
                        : (showearnings === 3 ?
                            <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/3pm.webp')} alt="Premsi.com earnings example" />
                            : (showearnings === 4 ?
                                <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/4pm.webp')} alt="Premsi.com earnings example" />
                                :
                                <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/5pm.webp')} alt="Premsi.com earnings example" />
                            )))}

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 14 }}>
                    <div onClick={() => setshowearnings(1)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 1 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(2)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 2 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(3)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 3 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(4)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 4 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(5)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 5 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                </div>
            </div>*/}

            <Footer />
        </>
    )
}

export default PremsiCalculator
